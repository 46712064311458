@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');

// TODO 1430 удалить старые шрифты

@font-face {
    font-display: swap;
    font-family: 'Avenir Next';
    font-weight: 700;
    src: url('../assets/fonts/AvenirNext/Avenir-Next-Bold.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Avenir Next';
    font-weight: 500;
    src: url('../assets/fonts/AvenirNext/Avenir-Next-Medium.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Avenir Next';
    font-weight: 400;
    src: url('../assets/fonts/AvenirNext/Avenir-Next-Regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'DIN Condensed';
    font-weight: 700;
    src: url('../assets/fonts/DINCondensed/DINCondensedBold.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Pro';
    font-weight: 900;
    src: url('../assets/fonts/GothamPro/GothamProBlack.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Pro';
    font-weight: 700;
    src: url('../assets/fonts/GothamPro/GothamProBold.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Pro';
    font-weight: 300;
    src: url('../assets/fonts/GothamPro/GothamProLight.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Pro';
    font-weight: 500;
    src: url('../assets/fonts/GothamPro/GothamProMedium.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Pro';
    font-weight: 400;
    src: url('../assets/fonts/GothamPro/GothamProRegular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Druk Text Wide';
    font-weight: 1000;
    src: url('../assets/fonts/DrukTextWide/DrukTextWide-Super.woff2') format('woff2');
}

// TODO 1430 удалить старые шрифты

@font-face {
    font-display: swap;
    font-family: 'Platform LC';
    font-weight: 500;
    src: url('../assets/fonts/PlatformLC/PlatformLC-Medium.woff2') format('woff2');
}
