@keyframes modalOverlayIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes modalBodyIn {
    0% {
        transform: scale(0.96);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes modalOverlayOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes modalBodyOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.96);
        opacity: 0;
    }
}

@keyframes animateArrow {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(3px);
    }
}

@keyframes slide {
    0% {
        transform: translateX(-100%) rotate(45deg);
    }
    25% {
        transform: translateX(150%) rotate(45deg);
    }
    100% {
        transform: translateX(150%) rotate(45deg);
    }
}

@keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em,
        2em -2em 0 0em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0,
        2em -2em 0 0.2em,
        3em 0 0 0,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em,
        2em -2em 0 0,
        3em 0 0 0.2em,
        2em 2em 0 0,
        0 3em 0 -1em,
        -2em 2em 0 -1em,
        -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0em 0 0,
        2em 2em 0 0.2em,
        0 3em 0 0em,
        -2em 2em 0 -1em,
        -3em 0em 0 -1em,
        -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 0em,
        0 3em 0 0.2em,
        -2em 2em 0 0,
        -3em 0em 0 -1em,
        -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 0,
        -2em 2em 0 0.2em,
        -3em 0 0 0,
        -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em,
        2em -2em 0 -1em,
        3em 0em 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 0,
        -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0,
        2em -2em 0 -1em,
        3em 0 0 -1em,
        2em 2em 0 -1em,
        0 3em 0 -1em,
        -2em 2em 0 0,
        -3em 0em 0 0,
        -2em -2em 0 0.2em;
    }
}

@keyframes showModal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes showModalContentDesktop {
    from {
        //transform: translateY(-50%) scale(0.8);
        transform: scale(0.8);
    }

    to {
        //transform: translateY(-50%) scale(1);
        transform: scale(1);
    }
}

@keyframes showModalContentMobile {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

@keyframes pulse {
    0%,
    100% {
        opacity: 0.6;
    }
    50% {
        opacity: 0.8;
    }
}

@keyframes toast-progress-bar {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

@keyframes showBlock {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes showCongratulation {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.3;
    }
}

/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-left1 {
    100% {
        transform: translateX(-100%);
    }
}

/* scrolling-left is continuous/repeatly text */
@keyframes scrolling-right1 {
    100% {
        transform: translateX(100%);
    }
}
