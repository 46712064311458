@import '@styles/variables';

.Toastify {
    --toastify-toast-min-height: 0;
    //.Toastify__toast
    &__toast {
        border-radius: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
        background: none !important;
        color: $textColor !important;
        display: block !important;
        @media (max-width: ($mobile)) {
            margin-bottom: 8px !important;
        }
    }

    //.Toastify__toast-body
    &__toast-body {
        padding: 0 !important;
        display: block !important;
    }

    //.Toastify__toast-container
    &__toast-container {
        @media (max-width: ($mobile)) {
            padding: 16px !important;
        }
    }
}
