@use 'sass:math';

@import '@styles/variables';

.checkbox {
    cursor: pointer;

    display: inline-grid;
    align-items: center;
    grid-template-columns: 18px auto;
    column-gap: 8px;
    transition: opacity 0.3s ease 0s;
    width: fit-content;

    @media (any-hover: hover) {
        &:not(.checkbox_active):hover {
            .checkbox__indicator {
                box-shadow: inset 0 0 0 1.5px var(--Wild-Dove);
            }
        }
    }

    //.checkbox_active
    &_active {
        .checkbox__indicator {
            background-color: var(--Thamar-Black);
            box-shadow: inset 0 0 0 1.5px var(--Thamar-Black);
        }
    }

    //.checkbox_circle
    &_circle {
        .checkbox__indicator {
            border-radius: 50%;
        }

        //.checkbox_active
        &.checkbox_active {
            .checkbox__indicator {
                background-color: var(--white);
                box-shadow: inset 0 0 0 5px var(--Thamar-Black);
            }
        }
    }

    //.checkbox_disabled
    &_disabled {
        cursor: not-allowed;
    }

    //.checkbox__indicator
    &__indicator {
        width: 18px;
        height: 18px;
        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--Bleached-Silk);
        box-shadow: inset 0 0 0 1.5px var(--Silver-Lake);

        transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s;
    }

    //.checkbox__text
    &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.2px;
    }

    //.checkbox__input
    &__input {
        flex: 0 0 0;
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    &:focus-visible {
        .checkbox__indicator {
            outline: 1.5px solid var(--Thamar-Black);
        }
    }
}
