@use 'sass:math';
@import '@styles/variables';

.container {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    padding: 16px 12px 16px 12px;
    width: 336px;
    height: auto;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;

    &__link_wrapper {
        display: flex;
    }

    &__btn {
        width: 100%;
        margin-top: 20px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: start;
        row-gap: 7px;
    }

    &__header {
        font-size: 20px !important;
        line-height: 140%;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.2;

        &__span {
          font-weight: 700;
          font-size: 14px;
          line-height: 1.2;
        }

        &__spacing{
          margin-left: 8px;
        }

        &__spacing_link {
          color: #077DEC;
          text-decoration: underline;
          margin-left: 8px;
        }

         &__link {
           color: #077DEC;
           text-decoration: underline;
         }
    }

    &__link {
      color: #077DEC;
      margin-top: 2px;
      text-decoration: underline;
  }
}

@media (min-width: ($mobile)) {
    .container {
        width: 562px;
        height: auto;
        align-items: start;
        padding: 20px 20px 24px 20px;

        &__btn {
            width: 130px;
            margin-top: 20px;
        }
    }
}
