@use 'sass:math';
@import '@styles/variables';

.container {
    display: flex;
    flex-direction: column;

    gap: 24px;

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
        color: var(--Thamar-Black);
    }

    &__text {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        color: var(--Thamar-Black);
        padding-bottom: 24px;
        border-bottom: 1px solid var(--Unicorn-Silver);
    }
    &__btn {
        width: 100%;
        margin: 0 auto;
        @media (min-width: ($mobile)) {
            width: fit-content;
        }
    }
}
