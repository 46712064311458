._default {
    path {
        transition: fill 0.5s ease 0s;
        fill: var(--main-text) !important;
    }
}
._headerNav {
    path {
        fill: var(--nav-link-header);
    }
}
._catalogFilterIcon {
    path {
        //fill: var(--main-color);
        fill: var(--nav-link-header);
    }
}
._strokeIcon {
    path {
        //fill: var(--main-color);
        stroke: var(--main-text);
    }
}
