@use 'sass:math';
@import '@styles/variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.question {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    color: var(--Thamar-Black);
    margin-bottom: 12px;
}

.variants {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--Unicorn-Silver);
    margin-bottom: 24px;
}

.checkbox {
    width: 100%;
    border: none;
    padding: 2px 0;
    font-size: 16px;
}

.label {
    font-size: 16px !important;
    color: var(--Thamar-Black);
}

.btn {
    width: 100%;
    @media (min-width: ($mobile)) {
        width: fit-content;
    }
}
