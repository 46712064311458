@use 'sass:math';
@import '@styles/variables';
@import "@styles/keyframes";

.button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 2px;

    text-align: center;

    border-style: solid;
    border-radius: 6px;

    & > span {
        padding: 1px 4px 0;
    }

    & svg {
        flex: 0 0 var(--icon-size);
        width: var(--icon-size);
        height: var(--icon-size);
    }

    &:focus-visible {
        outline: none;
    }

    transition: color 0.3s ease 0s, background-color 0.3s ease 0s, box-shadow 0.3s ease 0s;

    //.button_Black
    &_Black {
        color: var(--white);
        background-color: var(--Thamar-Black);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--Night-Rider);
            }
        }

        &:active {
            background-color: var(--Goshawk-Grey);
        }

        &:not(:active):focus-visible {
            box-shadow: 0 0 0 1.5px var(--white), 0 0 0 3.5px var(--Thamar-Black);
        }
    }

    //.button_White
    &_White {
        box-shadow: inset 0 0 0 1.5px var(--Thamar-Black);

        @media (any-hover: hover) {
            &:hover {
                color: var(--white);
                box-shadow: inset 0 0 0 1.5px var(--Night-Rider);
                background-color: var(--Night-Rider);
            }
        }

        &:active {
            background-color: var(--Goshawk-Grey);
        }

        &:not(:active):focus-visible {
            box-shadow: inset 0 0 0 1.5px var(--Thamar-Black), 0 0 0 1px var(--Thamar-Black);
            background-color: var(--Zhn-Zh-Bi-Pearl);
        }
    }

    //.button_Transparent
    &_Transparent {
        color: var(--Thamar-Black);
        @media (any-hover: hover) {
            &:hover {
                background-color: var(--White-Edgar);
            }
        }

        &:active {
            background-color: var(--Unicorn-Silver);
        }

        &:not(:active):focus-visible {
            box-shadow: inset 0 0 0 1.5px var(--Wild-Dove);
        }
    }

    //.button_zhenZhuBaiPearl
    &_zhenZhuBaiPearl {
        background-color: var(--Bleached-Silk);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--White-Edgar);
            }
        }

        &:active {
            background-color: var(--Unicorn-Silver);
        }

        &:not(:active):focus-visible {
            box-shadow: 0 0 0 2px var(--Wild-Dove);
        }
    }

    //.button_Red
    &_Red {
        color: var(--white);
        background-color: var(--Mandarin-Red);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--Dangerously-Red);
            }
        }

        &:active {
            background-color: var(--Cadillac-Coupe);
        }

        &:not(:active):focus-visible {
            box-shadow: 0 0 0 2px var(--Cherished-One);
        }
    }

    //.button_LightRed
    &_LightRed {
        color: var(--Mandarin-Red);
        background-color: var(--Placebo-Red);
        box-shadow: inset 0 0 0 1.5px var(--Just-A-Tease);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--Brides-Blush);
            }
        }

        &:active {
            box-shadow: inset 0 0 0 1.5px var(--Pinky-Promise);
            background-color: var(--Pinky-Promise);
        }

        &:not(:active):focus-visible {
            box-shadow: inset 0 0 0 1.5px var(--Just-A-Tease), 0 0 0 2px var(--Cherished-One);
        }
    }


    //.button_TransparentRed
    &_TransparentRed {
        color: var(--Mandarin-Red);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--Brides-Blush);
            }
        }

        &:active {
            background-color: var(--Pinky-Promise);
        }

        &:not(:active):focus-visible {
            box-shadow: 0 0 0 2px var(--Cherished-One);
        }
    }

    //.button_large
    &_large {
        padding: 4px 16px;
        min-height: 48px;

        font-weight: 600;
        line-height: 140%;

        --icon-size: 20px;
    }

    //.button_medium
    &_medium {
        padding: 4px 12px;
        min-height: 40px;

        font-size: 14px;
        font-weight: 500;
        line-height: 140%;

        --icon-size: 16px;
    }

    //.button_small
    &_small {
        padding: 4px 8px;
        min-height: 36px;

        font-size: 14px;
        line-height: 140%;

        --icon-size: 20px;
    }

    //.button_extra_small
    &_extra_small {
        padding: 4px 8px;
        border-radius: 16px;
        min-height: 28px;

        font-size: 13px;
        line-height: 140%;

        --icon-size: 20px;
    }

    &.button_disabled:not(.button_loading) {
        cursor: not-allowed !important;
        color: var(--Brainstem-Grey) !important;
        box-shadow: none !important;
        background-color: var(--Silver-Lake) !important;

        &.button_TransparentRed,
        &.button_Transparent {
            background-color: transparent !important;
        }
    }

    //.button_loading
    &_loading {
        cursor: wait !important;
        animation: pulse 1.5s infinite ease-in-out;
    }
}
