@use 'sass:math';
@import '@styles/variables';

._select {
    width: 100%;
    z-index: 2;
    border-radius: 10px !important;

    color: var(--Thamar-Black);
    line-height: 140%;
    background-color: var(--Bleached-Silk);

    //._select_selected
    &_selected {
        background-color: var(--white);
    }

    //._select_top
    &_top {
        ._select__menu {
            top: auto !important;
            bottom: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    @media (any-hover: hover) {
        ._select__control {
            transition: border-color 0.3s ease 0s;
        }
        ._select__dropdown-indicator > svg {
            transition: fill 0.3s ease 0s;
        }

        &:hover {
            ._select__control {
                border-color: var(--Wild-Dove) !important;
            }
        }
    }

    //._select__indicator-separator
    &__indicator-separator {
        display: none;
    }

    //._select__indicator
    &__indicator {
        position: relative;
        z-index: 2;
    }

    //._select__dropdown-indicator
    &__dropdown-indicator {
        padding: 0 !important;
        transition: rotate 0.3s ease 0s !important;
        transform-origin: center;
    }

    //._select__control
    &__control {
        --border-width: 1.5px;
        cursor: pointer !important;
        height: 48px;
        padding: 0 calc(16px - var(--border-width));
        box-shadow: none !important;

        border-radius: inherit !important;
        border: 1.5px solid var(--Silver-Lake) !important;

        //._select__control--menu-is-open
        &--menu-is-open {
            ._select__dropdown-indicator {
                rotate: 180deg;

            }
        }

        //._select__control--is-focused
        &--is-focused {
            box-shadow: 0 0 0 2px var(--Wild-Dove) !important;
        }

        //._select--is-disabled
        &--is-disabled {
            cursor: not-allowed;
            background: var(--Zhn-Zh-Bi-Pearl);
            color: var(--Silver-Lake);
        }
    }

    //._select__value-container
    &__value-container {
        padding-left: 0 !important;
    }

    //._select__single-value
    //._select__input-container
    &__single-value,
    &__input-container {

        color: inherit;
        line-height: inherit;
    }

    //._select__menu
    &__menu {
        overflow: hidden;
        background: var(--white) !important;
        border-radius: inherit !important;

        top: calc(100% + 8px) !important;

        min-width: max-content;
        box-shadow: 0 4px 14.2px 0 var(--Thamar-Black-10);
    }

    //._select__menu-list
    &__menu-list {
        max-height: 250px !important;
        padding: 8px !important;
    }

    //._select__menu-notice
    &__menu-notice {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    //._select__option
    &__option {
        //._select__option--is-selected
        &--is-selected {
        }

        & > button {
            width: 100%;
            justify-content: flex-start;
        }
    }

    //._select__placeholder
    &__placeholder {
        @extend ._ellipsis;
    }
}
