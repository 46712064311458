:root[data-theme='dark'] {
    --background-navigation: #354468;
    --background-navigation-hover: #3544688a;
    --footer-link: #5a72ff;
    --footer-bg: #172038;
    --footer-btn-bg: linear-gradient(360deg, #7a7a7a 0%, #ffffff 100%);
    --footer-btn-bg-hover: linear-gradient(360deg, #333333 0%, #ffffff 100%);
    --main-color: #1c2742;
    --main-bg: #1c2742;
    --custom-bg: #172038;
    --main-text: #fff;
    --gray-text: rgba(222, 222, 222, 0.56);
    --nav-link-header: rgba(255, 255, 255, 0.3607843137);
    --profile-item-bg: #1c2742;
    --profile-shadow-1: linear-gradient(91.1deg, rgba(160, 235, 244, 0.22) 8.59%, rgba(61, 140, 241, 0.22) 78.95%);
    --profile-shadow-2: linear-gradient(90.17deg, rgba(33, 129, 237, 0.52) 7.83%, rgba(0, 81, 172, 0) 77.49%);
    --profile-shadow-3: linear-gradient(180deg, rgba(20, 81, 154, 0.54) 0%, rgba(20, 81, 154, 0) 100%);
    --profile-subtitle: rgba(248, 242, 242, 0.8);
    --active-select: #22508e;
    --hover-select: #1d3b6570;
    --profile-panel-border: rgba(255, 255, 255, 0.2);
    --loader: #fff;
    --catalog-item-gradient: linear-gradient(0deg, #354468 0%, rgba(255, 255, 255, 0) 100%);
    --hover-fill: #172038;
    --catalog-item-bg: rgba(255, 255, 255, 0.3607843137);
    --card-bg: #354468;
    --slider-label-color: rgba(255, 255, 255, 0.56);
    --slider-item-gradient: linear-gradient(0deg, #1c2742 0%, rgba(28, 39, 66, 0) 100%);

    /* new UIKIT========================================================================================================================================================*/

    --new-main-bg: var(--new-black);

    --new-main-elements: var(--new-light);

    --new-active-tab-bg: var(--new-black);
}
