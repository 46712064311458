@import '@styles/variables';

.swiper-pagination-bullets {
    --swiper-pagination-bullet-size: 16px;
    --swiper-pagination-bullet-horizontal-gap: 2px;
    --swiper-pagination-bullet-inactive-color: transparent;
    --swiper-pagination-bullet-inactive-opacity: 1;

    .swiper-pagination-bullet:first-child {
        margin-left: 0 !important;
    }

    .swiper-pagination-bullet:last-child {
        margin-right: 0 !important;
    }

    //.swiper-pagination-bullets-dynamic
    &-dynamic {
        transform: none !important;

        .swiper-pagination-bullet {
            //transform: none !important;
        }
    }
}

.swiper-pagination-bullet {
    border: 1px solid var(--Thamar-Black);
    transition: background-color 0.3s ease;
    //.swiper-pagination-bullet-active
    &-active {
        --swiper-pagination-color: var(--Thamar-Black);
    }
}
