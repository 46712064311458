:root,
:root[data-theme='dark'] {
    /*TODO удалить старые цвета*/
    --new-light: #f9fafb;
    --new-black: #1a1c1e;
    /*TODO удалить старые цвета*/

    --white: #fff;
    --black: #000;
    --black-20: rgba(0, 0, 0, 0.2);
    --Thamar-Black: #1A1718;
    --Thamar-Black-5: rgba(26, 23, 24, 0.05);
    --Thamar-Black-10: rgba(26, 23, 24, 0.10);
    --Thamar-Black-30: rgba(26, 23, 24, 0.30);
    --Thamar-Black-40: rgba(26, 23, 24, 0.40);
    --Thamar-Black-44: rgba(26, 23, 24, 0.44);
    --Thamar-Black-80: rgba(26, 23, 24, 0.80);
    --absence-of-light: #14151A;
    --Nl-Blue: #0051FF;
    --Nl-Blue-30: hsla(221, 100%, 50%, 0.30);
    --molten-core: #FF5900;
    --porpoise: #dadada;
    --Unicorn-Silver: #E8E8E8;
    --Ruby-Dust: #E0115F;
    --Golden-Lock: #F4B81E;
    --Jupiter: #E2E2E2;
    --white-smoke: #F5F5F5;
    --waiting: #9D9C9D;
    --Zhn-Zh-Bi-Pearl: #F8F8F8;
    --Silver-Lake: #DEDDDD;
    --Merchant-Marine-Blue: #074DE3;
    --blue-hour: #0339AF;
    --Truesky-Gloxym: #94B6FF;
    --spacious-sky: #AEB7C9;
    --Silverfish: #8A94A9;
    --Periwinkle-Grey: #C2CEE8;
    --Brilliant-White: #E5EEFF;
    --Icy-Plains: #CDDCF8;
    --Frost-Fairy: #B6CCF4;
    --Bleached-Silk: #F2F2F2;
    --White-Edgar: #EDEDED;
    --Mandarin-Red: #E6483D;
    --Dangerously-Red: #D64137;
    --Cadillac-Coupe: #C4342A;
    --Cherished-One: #FF9494;
    --Placebo-Red: #FCEDEC;
    --Just-A-Tease: #F9D4D2;
    --Brides-Blush: #F8E3E1;
    --Pinky-Promise: #F6D3CF;
    --Brainstem-Grey: #B4B4B4;
    --Hydrargyrum: #9C9A9B;
    --Goshawk-Grey: #454344;
    --Asphalt-Grey: #5F5D5D;
    --Wild-Dove: #8C8B8B;
    --Cascading-White: #F6F6F6;
    --Shivering-Green: #28D27A;
    --Faint-Clover: #B2F4D2;
    --Steel-Wool: #767474;
    --Night-Rider: #312e2f;
}