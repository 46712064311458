:root {
    --background-navigation: #f1f2ff;
    --background-navigation-hover: #f1f2ff91;
    --footer-link: #5a72ff;
    --footer-bg: #f5f5f5;
    --footer-btn-bg: linear-gradient(360deg, #333333 0%, #000000 100%);
    --footer-btn-bg-hover: linear-gradient(360deg, #616161 0%, #000000cf 100%);
    --main-color: #fff;
    --main-bg: #fff;
    --custom-bg: transpatent;
    --main-text: #000;
    --gray-text: rgba(0, 0, 0, 0.56);
    --nav-link-header: rgba(0, 0, 0, 0.3607843137);
    --profile-item-bg: #eff1ff;
    --profile-shadow-1: linear-gradient(90.17deg, rgba(255, 32, 166, 0.52) 7.83%, rgba(255, 21, 162, 0) 77.49%);
    --profile-shadow-2: linear-gradient(91.1deg, rgba(255, 32, 166, 0.22) 8.59%, rgba(164, 57, 215, 0) 78.95%);
    --profile-shadow-3: linear-gradient(180deg, rgba(109, 26, 149, 0.54) 0%, rgba(109, 26, 149, 0) 100%);
    --profile-subtitle: rgba(52, 59, 63, 0.56);
    --active-select: #4c84d2;
    --hover-select: #86aee670;
    --profile-panel-border: rgba(0, 0, 0, 0.08);
    --loader: grey;
    --catalog-item-gradient: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    --hover-fill: rgba(0, 0, 0, 0.1);
    --catalog-item-bg: #fff;
    --card-bg: #fff;
    --slider-label-color: rgba(0, 0, 0, 0.56);
    --slider-item-gradient: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);

    /* new UIKIT========================================================================================================================================================*/

    --new-main-bg: var(--new-light);

    --new-main-elements-reverse: var(--new-light);
}
