@use 'sass:math';
@import '@styles/variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--Unicorn-Silver);
    margin-bottom: 24px;
    color: var(--Thamar-Black);

    &__ratingLabel {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 12px;
    }
    &__smileBox {
        position: relative;
        margin-bottom: 20px;
        min-height: 20px;
    }

    &__smileIcon {
        position: absolute;
        top: 0;
        width: 36px;
        display: flex;
        justify-content: center;
    }

    &__slider {
        -webkit-appearance: none;
        width: 100%;
        height: 20px;
        border-radius: 12px;
        background: var(--Thamar-Black);
        border: 1px solid var(--Unicorn-Silver);
        outline: none;
        padding: 0 27px;
        margin-bottom: 20px;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 36px;
            height: 36px;
            border-radius: 6px;
            cursor: pointer;
            background: var(--Thamar-Black) url('/assets/images/quiz/dots.svg') center no-repeat;
        }

        &::-moz-range-thumb {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            cursor: pointer;
            background: var(--Thamar-Black) url('/assets/images/quiz/dots.svg') center no-repeat;
        }
    }

    & &__textarea {
        width: 100%;
        height: 100px;
    }
}
