@import '~react-image-gallery/styles/scss/image-gallery.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-color-scheme: dark) {
    //html {
    //    color-scheme: dark;
    //}
    //
    //body {
    //    color: white;
    //    background: black;
    //}
}

* {
    padding: 0;
    margin: 0;
    border: 0;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

aside,
nav,
footer,
header,
section {
    display: block;
}

html {
    font-size: $fontSize;
    line-height: 1 !important;
}

html,
body {
    z-index: 0;

    height: 100%;
    min-width: $minWidth + px;
    background-color: $baseBg;

    &._lock {
        overflow: hidden;
    }
}

body {
    position: relative;
    color: $textColor;
    line-height: 1;
    font-family: $fontFamily;

    // text-rendering: optimizeLegibility;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    &:before {
        content: '';
        position: fixed;
        z-index: $ZIndexBurgerOverlay;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        transition: background 0.3s ease 0s;
    }

    &._lock {
        @media (max-width: ($mobile)) {

            //._lock_overlay
            &_overlay {
                &:before {
                    background: rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

input,
button,
textarea {
    font-family: $fontFamily;
    font-size: inherit;
    background: transparent;
}

input::-ms-clear {
    display: none;
}

input:focus,
textarea:focus {
    outline: none;
}

button {
    cursor: pointer;
    background-color: inherit;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}

#__next {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
}

._main {
    padding: 64px 0;

    flex: 1 1 auto;
    min-width: 0;
    transition: background 0.3s ease 0s;

    @media (max-width: ($mobile)) {
        padding: 32px 0 40px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 600000s 0s,
    color 600000s 0s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: inherit;
    background-color: inherit;
    box-shadow: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.swiper {
    min-width: 0;
}

w3m-modal {
    z-index: $ZIndexWeb3Modal;
}
