@use 'sass:math';
@import '@styles/variables';
@import '@styles/keyframes';

.modal {

    //.modal__root
    &__root {
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
    }

    //.modal__overlay
    &__overlay {
        pointer-events: fill;

        position: fixed;
        z-index: $ZIndexModal;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--Thamar-Black-40);
        backdrop-filter: blur(2px);
        overflow-x: hidden;

        display: flex;

        padding: 40px 16px;

        opacity: 0;

        //.modal__overlay_parent
        &_parent {
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            overflow: visible !important;
            padding: 0 !important;
            border-radius: inherit;

            .modal__body {
                min-width: auto;

                @media (max-width: ($mobile)) {
                    margin-bottom: auto;
                    border-bottom-right-radius: var(--border-radius);
                    border-bottom-left-radius: var(--border-radius);
                }
            }
        }

        //.modal__overlay_open
        &_open {
            animation: modalOverlayIn 300ms 1 linear forwards;
        }

        //.modal__overlay_close
        &_close {
            animation: modalOverlayOut 300ms 1 linear forwards;
        }
    }

    //.modal__body
    &__body {
        outline: none;

        margin: auto;
        max-width: 800px;
        min-width: calc(($minWidth - $containerPadding * 2)) + px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: ($mobile)) {
            margin-bottom: 0;
        }

        transform: scale(0.96);
        opacity: 0;

        //.modal__body_open
        &_open {
            animation: modalBodyIn 300ms 1 linear forwards;
        }

        //.modal__body_close
        &_close {
            animation: modalBodyOut 300ms 1 linear forwards;
        }
    }
}