$textColor: var(--Thamar-Black);
$baseBg: var(--white);


$fontFamily: 'Inter Tight', sans-serif;
// TODO 1430 удалить старые шрифты
$fontGothamPro: 'Gotham Pro';
$fontDINCondensed: 'DIN Condensed', 'Barlow';
$fontDrukTextWide: 'Druk Text Wide';
// TODO 1430 удалить старые шрифты

$fontPlatformLC: 'Platform LC';

$fontSize: 16px;

$minWidth: 360;
$maxWidth: 1920;
$maxWidthContainer: 1360;
$containerPadding: 16;
$containerWidth: $maxWidthContainer + $containerPadding * 2;
$containerMedia: $containerWidth + px;
$tablet: 991.98px;
$mobile: 767.98px;
$second_mobile: 479.98px;

// AtToys variables

$maxWidthAr: 1920;
$maxWidthContainerAr: 1216;
$containerPaddingAr: 20;

$containerWidthAr: $maxWidthContainerAr + $containerPaddingAr * 2 - 1 + 0.98; // 1256  // 1255.98

$containerMediaAr: $containerWidthAr + px;
$tablet: 991.98px;
$mobile: 767.98px;
$second_mobile: 479.98px;

$ZIndexConfetti: 1002;
$ZIndexWeb3Modal: 1001;
$ZIndexModal: 1000;
$ZIndexBurger: 999;
$ZIndexHeader: 998;
$ZIndexHeaderMobile: 997;
$ZIndexBurgerOverlay: 996;
