@use 'sass:math';
@import '@styles/variables';

.container {
    margin-bottom: 20px;

    &__stage {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        text-align: end;
        color: var(--Thamar-Black);
    }
}

.bar {
    margin-top: 4px;
    border: 1px solid var(--Unicorn-Silver);
    border-radius: 12px;

    &__filled {
        background-color: var(--Thamar-Black);
        border-radius: 12px;
        height: 18px;
    }
}
