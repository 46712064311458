@import '@styles/variables';
@import '@styles/mixins';
@import '@styles/keyframes';

#toy_qr {
    transition: filter 0.3s ease 0s;
}

html[data-theme='light'] #toy_qr {
    filter: invert(1);
    transition: filter 0.3s ease 0s;
}


._container {
    max-width: $containerWidth + px;
    padding: 0 $containerPadding + px;
    width: 100%;
    margin: 0 auto;
}

.icon {
    transition: fill 0.3s ease 0s;
    -webkit-transition: fill 0.3s ease 0s;
}


._smallTitle {
    font-family: $fontGothamPro;
    font-weight: 700;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--new-main-elements);
    transition: color 0.3s ease 0s;
}

._label {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: var(--new-main-elements);
    transition: color 0.3s ease 0s;
}

#submit {
    font-family: $fontGothamPro;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    min-height: 48px;
    padding: 4px 20px;
    border: 4px solid transparent;

    border-radius: 24px;
    transition: all 0.3s ease 0s;

    color: var(--new-light);
    background: var(--Nl-Blue);

    & > svg {
        fill: currentcolor;
    }

    @media (min-width: ($mobile)) {
        padding: 6px 29px 4px;
    }

    &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
    }
}

._ibg {
    position: relative;

    img,
    & > svg,
    ._skeleton,
    ._skeleton_toy {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;

        //top: 50%;
        //left: 50%;
        //transform: translate(-50%, -50%);
    }

    ._skeleton_toy {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

._skeleton_toy {
    min-width: 32px;
    max-width: 70px;
    width: 38%;
    margin: 0 auto;
}

._relative {
    position: relative;
}

._img {
    font-size: 0;
    color: transparent;
    opacity: 0;
    transition: opacity 0.3s ease 0s;

    //._img_loaded
    &_loaded {
        opacity: 1;
    }
}

._imgContain {
    object-fit: contain !important;
    z-index: 100;
}

._grid {
    display: grid;

    &:not(._grid_row) {
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(auto, 180px));
        column-gap: 95px;
        row-gap: 24px;

        @media (max-width: ($tablet)) {
            column-gap: 20px;
        }

        @media (max-width: ($mobile)) {
            grid-template-columns: repeat(auto-fit, minmax(auto, 157px));
        }
    }

    //._grid_three
    &_three {
        @media (max-width: ($mobile)) {
            grid-template-columns: repeat(auto-fit, minmax(auto, 98px));
        }
    }

    //._grid_row
    &_row {
        max-width: 630px;
        width: 100%;
        margin: 0 auto;
    }
}

._sections {
    & > * {
        &:not(:last-child) {
            @media (min-width: ($mobile)) {
                padding-bottom: 32px;
            }
        }

        &:not(:first-child) {
            border-top: 1px solid var(--new-border);
        }
    }
}

._desktop {
    @media (max-width: ($mobile)) {
        display: none !important;
    }
}

._mobile {
    @media (min-width: ($mobile)) {
        display: none !important;
    }
}

._tablet {
    @media (max-width: ($tablet)) {
        display: none !important;
    }
}

._tablet_min {
    @media (min-width: ($tablet)) {
        display: none !important;
    }
}

// arToys

._arToysContainer {
    max-width: $maxWidthContainerAr + px;
    padding: 0 $containerPaddingAr + px;
    width: 100%;
    margin: 0 auto;
}

._arToysTitle {
    font-family: $fontDrukTextWide;
    color: var(--white);
    font-size: 30px;
    font-weight: 1000;
    line-height: 120%;

    &:not(._arToysTitle_default) {
        & > span {
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    //._arToysTitle_pointer
    &_pointer {
        cursor: pointer;
    }

    //._arToysTitle_disabled
    &_disabled {
        cursor: not-allowed;
    }

    //._arToysTitle_loading
    &_loading {
        cursor: wait;
        animation: pulse 2s infinite ease-in-out;
    }
}

._icon_divider {
    margin: 0 auto;
}

._lineDivider {
    background: var(--Silver-Lake);

    //._lineDivider_horizontal
    &_horizontal {
        height: 1px;
        width: 100%;
    }

    //._lineDivider_vertical
    &_vertical {
        width: 1px;
        height: 100%;
    }
}

._ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

._countdown {
    font-family: $fontDrukTextWide !important;
    font-weight: 1000 !important;
    --fcc-separator-color: var(--white) !important;
    --fcc-digit-color: var(--black) !important;
    --fcc-shadow: inset 0 10px 0 0 var(--white) !important;
    --fcc-background: var(--white) !important;
    --fcc-divider-color: transparent !important;
    --fcc-divider-height: 0 !important;

    --fcc-digit-font-size: 18px !important;
    --fcc-separator-size: 3px !important;
    --fcc-digit-block-width: 27px !important;
    --fcc-digit-block-height: 23px !important;

    & > *:nth-child(even) {
        margin: 0 2px !important;
    }

    & > *:nth-child(odd) {
        & > *:not(:last-child) {
            margin-right: 0 !important;
        }
    }

    //._countdown_small
    &_small {
        @media (max-width: ($mobile)) {
            font-weight: 500 !important;
            --fcc-digit-font-size: 15px !important;
            --fcc-separator-size: 2px !important;
            --fcc-separator-margin: 2px !important;
            --fcc-digit-block-width: 22px !important;
            --fcc-digit-block-height: 17px !important;
        }
    }
}

._sphereBg {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 1533px;
        height: 1533px;
        top: 0;
        left: 50%;
        transform: translate(-50%, -14%);
        border-radius: 50%;
        opacity: 0.4;
        background: linear-gradient(to bottom right, #e43fff 8%, rgba(88, 241, 251, 0.27) 49%) bottom right / 50% 50% no-repeat,
        linear-gradient(to bottom left, #e43fff 8%, rgba(88, 241, 251, 0.27) 49%) bottom left / 50% 50% no-repeat,
        linear-gradient(to top left, #e43fff 8%, rgba(88, 241, 251, 0.27) 49%) top left / 50% 50% no-repeat,
        linear-gradient(to top right, #e43fff 8%, rgba(88, 241, 251, 0.27) 49%) top right / 50% 50% no-repeat;
        filter: blur(26px);

        @media (max-width: 1533px) {
            width: 100%;
            height: auto;
            padding-bottom: 100%;
        }

        @media (max-width: ($tablet)) {
            transform: none;
            left: 0;
        }
    }
}

ul._numberedList {
    counter-reset: count;
    list-style-type: none;

    & > li {
        display: grid;
        column-gap: 4px;
        grid-template-columns: auto 1fr;

        &:before {
            content: counter(count) ".";
            counter-increment: count;
        }
    }
}


._fullscreen {
    --marginTop: -88px;

    margin-top: var(--marginTop);
    padding-top: calc(var(--marginTop) * -1 + 22px);

    @media (max-width: ($mobile)) {
        --marginTop: -84px;
        padding-top: calc(var(--marginTop) * -1 + 8px);
    }
}


// RTOYS ================================================================================================

._icon {
    display: inline-block;
    transition: fill 0.3s ease 0s;
    -webkit-transition: fill 0.3s ease 0s;
}

._divider {
    height: 1px;
    background: var(--Unicorn-Silver);
}

._strokeTitle {
    color: var(--white);
    font-family: $fontPlatformLC;
    font-size: 44px;
    -webkit-text-stroke: 8px transparent;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(90deg, var(--Thamar-Black), var(--Thamar-Black));
    -webkit-background-clip: text;
    background-clip: text;

    @media (max-width: ($mobile)) {
        font-size: 24px;
        -webkit-text-stroke: 4px transparent;
    }
}


._title {
    font-family: $fontPlatformLC;
    font-size: 72px;
    font-weight: 500;
    line-height: 120%;

    @media (max-width: ($tablet)) {
        font-size: 44px;
    }
}

._toysGrid {
    display: grid;
    align-content: start;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));

    @media (max-width: ($mobile)) {
        margin: 0 (-$containerPadding + px);
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}

._collectionsGrid {
    display: grid;
    align-content: start;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 325px);
    grid-auto-rows: minmax(497px, auto);
    gap: 20px;

    @media (max-width: ($mobile)) {
        grid-template-columns: 100%;
    }
}

._artistsGrid {
    display: grid;
    align-content: start;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 272px);
    grid-auto-flow: dense;
    row-gap: 20px;

    @media (max-width: ($mobile)) {
        grid-template-columns: repeat(auto-fit, 180px);
        margin: 0 (-$containerPadding + px);
    }
}

._feedGrid {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: ($mobile)) {
        margin: 0 (-$containerPadding + px);
        gap: 0;
    }
}


._noneSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


._instanceId {
    font-family: $fontPlatformLC;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
}

._sortingGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 12px;

    & > * {
        flex: 0 0 auto;
    }
}

._shadow {
    position: relative;
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 80%;
        aspect-ratio: 1;
        bottom: 0;
        left: 50%;
        background: radial-gradient(50% 50% at 50% 50%, var(--black-20) 39.58%, rgba(0, 0, 0, 0) 100%);
        border-radius: 50%;
        transform-style: preserve-3d;
        transform: translate(-50%, 50%) rotateX(95deg);
    }
}


._paymentsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    @media (max-width: ($mobile)) {
        display: flex;
        flex-wrap: wrap;

        & > * {
            flex: 1 1 50%;

            &:nth-child(3n) {
                flex: 1 1 100%;
            }
        }
    }

    //._paymentsGrid_ruby
    &_ruby {
        @media (max-width: ($mobile)) {
            & > * {
                flex: 1 1 50%;

                &:nth-child(3n) {
                    flex: 1 1 50%;
                }
            }
        }
    }
}
