@use 'sass:math';
@import '@styles/variables';

.container {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    padding: 16px 12px;
    width: 336px;
    background: var(--white);
    border-radius: 12px;
    overflow: hidden;

    &__header {
        font-family: $fontPlatformLC;
        font-weight: 500;
        font-size: 28px;
        line-height: 1.2;
        color: var(--Thamar-Black);
        margin-bottom: 40px;
    }
}

@media (min-width: ($mobile)) {
    .container {
        width: 562px;
        align-items: start;
        padding: 24px 32px;
    }
}
