/* Basic editor styles */
@use "sass:math";
@import "@styles/variables";


.ProseMirror>*+* {
    margin-top: 0 !important;
}

.tiptap {
    :first-child {
        margin-top: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 15px;

    // margin-left: 30px;

    outline: none !important;

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 0.4rem;

        li p {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        text-wrap: pretty;
    }

    h1,

    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }

    h1 {
        font-size: 44px;
        font-weight: 600;
        line-height: 120%;
        color: var(--thamar-black);
        word-break: break-word;

        @media (max-width: ($mobile)) {
            font-size: 24px;
        }
    }

    p {
        line-height: 140%;
        color: var(--thamar-black);
        font-size: 18px;

        ::selection {
            background: var(--Nl-Blue-30);
        }
    }


    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: var(--black);
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    a {
        text-decoration: none;
        color: var(--Nl-Blue);
    }

    pre {
        background: var(--black);
        border-radius: 0.5rem;
        color: var(--white);
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
        }
    }

    blockquote {
        border-left: 4px solid var(--Unicorn-Silver);
        padding-left: 10px;
    }
}

/* React component */
.react-component {
    position: relative;

    button {
        position: absolute;
        left: -60px;
        top: -8px;

        @media (max-width: ($mobile)) {
            margin-left: 60px;
        }
    }

    .file-upload {
        position: absolute;
        left: -30px;
        top: -5px;
        cursor: pointer;
    }

    .content {
        &.is-editable {
            border-radius: 0.5rem;
        }
    }
}

// для компонента с плюсом, плюс должен сдвигаться пока компонент пустой
.node-reactcomponent.is-empty {
    @media (max-width: ($mobile)) {
        margin-left: 60px;
    }
}

.is-empty::before {
    color: var(--Wild-Dove);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

.image-node {
    position: relative;
    aspect-ratio: 16/9;

    :focus {
        border: 3px solid red;
    }

    img {

        :focus {
            border: 3px solid red;
        }

        border-radius: 12px;
    }
}

.tip-tap-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__info {
        display: flex;
        justify-content: space-between;
    }
}