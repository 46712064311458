@use 'sass:math';
@import '@styles/variables';

.textareaBox {
    width: 100%;
    //.textareaBox__label
    &__label {
        font-weight: 500;
        line-height: 140%;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    //.textareaBox__row
    &__row {
    }

    //.textareaBox__textarea
    &__textarea {
        resize: none;
        min-width: 0;

        width: 100%;

        padding: 13px 16px;
        height: 250px;
        border-radius: 8px;
        box-shadow: inset 0 0 0 1.5px var(--Silver-Lake);
        background: var(--Bleached-Silk);

        transition: background 0.3s ease 0s, box-shadow 0.3s ease 0s;


        @media (any-hover: hover) {
            &:hover {
                box-shadow: inset 0 0 0 1.5px var(--Wild-Dove);
            }
        }

        &:focus-within {
            outline: none;
            box-shadow: inset 0 0 0 1.5px var(--Goshawk-Grey), 0 0 0 2px var(--Wild-Dove);
            background: var(--white);

        }

        //.textareaBox__row_filled
        &_filled {
            box-shadow: inset 0 0 0 1.5px var(--Silver-Lake);
            background: var(--white);
        }

        //.textareaBox__row_disabled
        &_disabled {
            background: var(--Zhn-Zh-Bi-Pearl);
            color: var(--Silver-Lake);
        }

        &::placeholder {
            color: var(--Brainstem-Grey);
        }
    }
}
